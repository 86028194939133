import { AuthGuard, ModalProvider, SmartHomeRedirect } from 'crunch-components';
import PageWithSidebar from 'layouts/PageWithSidebar';
import actualsRouting from 'pages/actuals/ActualsRouting';
import globalRouting from 'pages/global/GlobalRouting';
import MarkmiLogin from 'pages/login/MarkmiLoginView';
import predictionEvaluationRouting from 'pages/prediction-evaluation/PredictionEvaluationRouting';
import recommendationsRouting from 'pages/recommendations/RecommendationsRouting';
import strategyRouting from 'pages/strategy/StrategyRouting';
import uploadsRouting from 'pages/uploads/UploadsRouting';
import userManagementRouting from 'pages/user-management/UserManagementRouting';
import {
	createBrowserRouter,
	createRoutesFromElements,
	Route,
} from 'react-router-dom';
import { DEFAULT_PATHS } from 'utils/routerUtils';
import PrivateProviders from './components/PrivateProviders';

const router = createBrowserRouter(
	createRoutesFromElements(
		<Route path="/" element={<ModalProvider />}>
			<Route
				index
				element={<SmartHomeRedirect homePaths={DEFAULT_PATHS} />}
			/>
			<Route
				path="*"
				element={<SmartHomeRedirect homePaths={DEFAULT_PATHS} />}
			/>
			<Route
				element={
					<AuthGuard
						accessibleBy="public-only"
						privateHomePath={DEFAULT_PATHS['private']}
					/>
				}
			>
				<Route path="login" element={<MarkmiLogin />} />
			</Route>
			<Route
				element={
					<AuthGuard
						accessibleBy="private-only"
						publicHomePath={DEFAULT_PATHS['public']}
					/>
				}
			>
				<Route element={<PrivateProviders />}>
					<Route element={<PageWithSidebar />}>
						{strategyRouting}
						{actualsRouting}
						{recommendationsRouting}
						{globalRouting}
						{uploadsRouting}
						{userManagementRouting}
						{predictionEvaluationRouting}
					</Route>
				</Route>
			</Route>
		</Route>,
	),
);

export default router;
