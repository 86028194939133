import dayjs from 'dayjs';
import { useState } from 'react';

import { GET_ALL_PRODUCTS, GET_PRODUCTS_SCHEMA } from 'api/objectives-v2';
import FullFilterMenu, {
	FullFilterMenuProps,
} from 'components/Filter/FullFilterMenu';
import { FilterMap } from 'components/Filter/FullFilterMenu.types';
import {
	LinearProgress,
	TableHeaderButton,
	TableV2,
	Tag,
} from 'crunch-components';
import useChannelQuery from 'hooks/channels/useChannelQuery';
import { useProductCategoriesHeadings } from 'hooks/useHeadings';

import {
	AllProductsDataType,
	ObjectivesGroupBasicDataType,
	ProductsSchemaDataType,
} from '../types/objectives';
import { useEventCallback } from './hooks';
import { PRODUCTS_SCHEMA_KEY } from './queries';

type ProductsProps = {
	objective: ObjectivesGroupBasicDataType;
	setFilters: (filters: FilterMap) => void;
	filters: FilterMap;
	filterQuery: FullFilterMenuProps['filterQuery'];
};

type SortType = {
	key: string;
	direction: string;
};

type PaginationType = {
	page: number;
	size: number;
};

export const Products = ({
	objective,
	setFilters,
	filters,
	filterQuery,
}: ProductsProps) => {
	const [prevFilters, setPrevFilters] = useState(filters);
	const [pagination, setPagination] = useState<PaginationType>({
		page: 1,
		size: 50,
	});
	const [sort, setSort] = useState<SortType>({
		key: 'pid',
		direction: 'desc',
	});

	const { disabledHeadings, toggleHeading, resetHeadings } =
		useProductCategoriesHeadings();

	const { isLoading: isSchemaLoading, data: schema = [] } = useChannelQuery<
		ProductsSchemaDataType,
		unknown,
		ProductsSchemaDataType
	>(PRODUCTS_SCHEMA_KEY, GET_PRODUCTS_SCHEMA, {
		staleTime: 5 * 60 * 1000,
	});

	const params: string[][] = [
		['page', String(pagination.page)],
		['size', pagination.size.toString()],
		...Array.from(filters.entries()).map(([id, filterValue]) => [
			'filter',
			`"id":"${id}","value":${JSON.stringify(filterValue.value)}`,
		]),
	];

	// convert id to bq_name for sorting filter
	const heading = schema.find(({ id }) => id === sort.key);
	const sortParam =
		heading !== undefined
			? ['sort', `${heading.id}:${sort.direction}`]
			: null;

	if (sortParam !== null) {
		params.push(sortParam);
	}

	const { isFetching: isProductsFetching, data: productsData } =
		useChannelQuery<AllProductsDataType, unknown, AllProductsDataType>(
			[
				'products',
				pagination,
				Object.fromEntries(filters),
				sort,
				objective.id,
			],
			() => GET_ALL_PRODUCTS(new URLSearchParams(params)),
			{
				staleTime: 5 * 60 * 1000,
				keepPreviousData: true,
			},
		);

	const headings = schema.map((s) => ({
		id: s.id,
		label: s.name,
		tooltip: s.tooltip ?? undefined,
		sortable: s.sortable,
		align: 'center',
		// @ts-ignore
		enabled: !disabledHeadings.includes(s.id),
	}));

	if (filters !== prevFilters) {
		setPrevFilters(filters);
		setPagination({ ...pagination, page: 1 });
	}

	const handleSortChange = (newSort: SortType) => {
		setSort(newSort);
		setPagination({ ...pagination, page: 1 });
	};

	const renderCell = useEventCallback((row: any, columnId: any) => {
		const { field_type: fieldType } =
			schema.find(({ id }) => id === columnId) ?? {};

		if (fieldType === 'date') {
			return dayjs(row?.[columnId]).format('DD/MM/YYYY');
		}

		if (fieldType === 'image') {
			return row?.[columnId] ? (
				<img
					className="inline-block w-16"
					src={row?.[columnId]}
					alt=""
				/>
			) : null;
		}
		if (columnId === 'product_group') {
			return (
				<div className="inline-block max-w-[180px] truncate">
					{row?.[columnId]?.map((group: any) => (
						<Tag
							key={`${row?.product_id}_${group}`}
							label={group}
						/>
					))}
				</div>
			);
		}

		return row?.[columnId]?.toString();
	});

	const isLoading = isProductsFetching || isSchemaLoading;

	return (
		<>
			<div className="absolute left-32 right-0 top-0">
				<LinearProgress visible={isLoading} />
			</div>
			<div className="mt-6 flex flex-col space-y-2">
				<div>
					{!isProductsFetching &&
						Object.prototype.hasOwnProperty.call(
							productsData || {},
							'total',
						) && (
							<p className="text-xs text-ca-black">
								<strong>
									{productsData?.total?.toLocaleString(
										'en-US',
									)}
								</strong>{' '}
								products match the selected filters
							</p>
						)}
				</div>
				<div className="flex flex-col gap-2 pr-3">
					<div className="flex justify-between">
						<div className="grow self-center">
							<FullFilterMenu
								filterQuery={filterQuery}
								filters={filters}
								setFilters={setFilters}
								applyOnChange
							/>
						</div>
						<div className="flex items-center">
							<TableHeaderButton
								headings={headings}
								loading={isSchemaLoading}
								onChange={toggleHeading}
								onReset={
									disabledHeadings.length
										? resetHeadings
										: undefined
								}
							/>
						</div>
					</div>
					<TableV2
						headings={headings.filter(({ enabled }) => enabled)}
						rows={productsData?.items}
						rowKey="product_id"
						renderCell={renderCell}
						paginationActivePageClassName="bg-mi-velvet-lilac"
						pagination={{
							currentPage: pagination?.page,
							items: productsData?.total,
							itemsPerPage: pagination?.size,
							itemsPerPageOptions: [10, 25, 50, 100],
						}}
						// @ts-ignore
						onPageChange={(page) =>
							setPagination((p) => ({ ...p, page }))
						}
						// @ts-ignore
						onItemsPerPageChange={(size) =>
							setPagination({ size, page: 1 })
						}
						sort={sort}
						onSortChange={handleSortChange}
						loading={isProductsFetching}
						itemsLoading={pagination?.size}
						emptyState="No products found for the selected filters..."
						maxHeightTable="max-h-[40vh] overflow-y-scroll"
					/>
				</div>
			</div>
		</>
	);
};
