import { User } from '@auth0/auth0-react';
import { ChannelsDataType, GET_CHANNELS } from 'api/channels';
import { LIST_USERS, UPDATE_ACCESS, UsersDataType } from 'api/users';
import { TableV2 } from 'crunch-components';
import useChannelQuery from 'hooks/channels/useChannelQuery';
import { useQuery } from 'react-query';

const ALL_CHANNEL_ROLES = new Set([
	'superadmin',
	'admin',
	'all-channel-user',
	'data-engineer',
]);

const renderCell = (
	row: User,
	columnId: string,
	channels: ChannelsDataType,
	refetchUsers: () => void,
) => {
	switch (columnId) {
		case 'Name':
			return (
				<div>
					<span>{row.name}</span>
				</div>
			);
		case 'Email':
			return <span>{row.email}</span>;
		case 'Roles':
			return <span>{row.roles.join(', ')}</span>;
		case 'Channels':
			if (row.roles.some((r: string) => ALL_CHANNEL_ROLES.has(r))) {
				return <span>All</span>;
			}

			// TODO: proper select component like
			// * https://headlessui.com/react/listbox#selecting-multiple-values
			return (
				<form className="max-w-sm">
					<select
						multiple
						className="block w-full rounded-lg border border-ca-gray bg-ca-ghost-white p-2.5 text-sm text-ca-gray focus:border-ca-purple"
						value={row.access.channels}
						onChange={async (e) => {
							await UPDATE_ACCESS(row.id, {
								channels: Array.from(
									e.target.selectedOptions,
									(option) => option.value,
								),
							});
							refetchUsers();
						}}
					>
						{channels.map(({ name }) => (
							<option key={name} value={name}>
								{name}
							</option>
						))}
					</select>
				</form>
			);
		default:
			return null;
	}
};

const UserManagementTable = () => {
	const {
		isLoading: usersLoading,
		data: users,
		refetch: refetchUsers,
	} = useChannelQuery<UsersDataType, unknown, UsersDataType>(['users'], () =>
		LIST_USERS(),
	);

	const { isLoading: channelsLoading, data: channels } = useQuery<
		ChannelsDataType,
		unknown,
		ChannelsDataType
	>('channels', GET_CHANNELS, {
		staleTime: 5 * 60 * 1000,
	});

	return (
		<TableV2
			loading={usersLoading || channelsLoading}
			rows={users ?? []}
			renderCell={(r, c) =>
				renderCell(r, c, channels ?? [], refetchUsers)
			}
			rowKey="id"
			headings={[
				{
					id: 'Name',
					label: 'Name',
				},
				{
					id: 'Email',
					label: 'Email',
				},
				{
					id: 'Roles',
					label: 'Roles',
				},
				{
					id: 'Channels',
					label: 'Channel access',
				},
			]}
		/>
	);
};

export default UserManagementTable;
