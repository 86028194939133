import {
	ActualsIcon,
	BarChartIcon,
	ChartScatterBoldIcon,
	cn,
	HelpIcon,
	Logo,
	StrategyIcon,
	Tooltip,
	UploadBoldIcon,
	UserManagementBoldIcon,
} from 'crunch-components';
import { NavLink, useNavigate } from 'react-router-dom';

import { useAuth0 } from '@auth0/auth0-react';
import useChannelStore from 'hooks/channels/useChannelStore';
import { ElementType } from 'react';
import { isFeatureEnabled } from 'utils/featureUtils';
import { DEFAULT_PATHS } from 'utils/routerUtils';
import StratosLogOutButton from './StratosLogOutButton';

type SidebarNavLinkProps = {
	icon: ElementType;
	label: string;
	to: string;
	disabled?: boolean;
	tooltip?: string;
};

/*  Note 07 november 2024 ward:
	 <SidebarNavLink> & <SidebarCircleLink> not in crunch-components
	 because charlie rebrand is coming and
	 I think that will require these components to be unique per app,
	 so I am not putting in the work do share them now.
*/
function SidebarNavLink(props: SidebarNavLinkProps) {
	const Icon = props.icon;
	return (
		<Tooltip content={props.tooltip} placement="right">
			<NavLink
				to={props.to}
				className={({ isActive, isPending }) =>
					cn(
						'group/li',
						isActive ? 'active' : 'not-active',
						isPending ? 'pending' : 'not-pending',
						(props.disabled ?? false)
							? 'cursor-not-allowed opacity-50'
							: 'cursor-pointer hover:text-ca-purple',
					)
				}
			>
				<li className="relative flex cursor-pointer flex-col flex-wrap items-center py-5 text-ca-gray transition-all before:absolute before:left-0 before:top-0 before:h-full before:bg-ca-purple before:transition-all hover:border-ca-purple hover:text-ca-purple group-[.active]/li:before:w-1 group-[.not-active]/li:before:w-0">
					<Icon
						className={cn(
							'w-5 transition-colors',
							'group-[.active]/li:text-ca-purple',
						)}
					/>
					<span className="mt-4 h-0 max-w-4/5 text-center text-xs font-semibold uppercase tracking-tighter opacity-0 transition-all group-hover/ul:h-4 group-hover:h-4 group-hover/ul:opacity-100 group-[.active]/li:h-4 group-[.active]/li:text-ca-purple group-[.active]/li:opacity-100">
						{props.label}
					</span>
				</li>
			</NavLink>
		</Tooltip>
	);
}

export function SidebarCircleLink(props: {
	icon: ElementType;
	tooltip: string;
	to?: string;
	onClick?: () => void;
}) {
	const navigate = useNavigate();

	function handleOnClick() {
		if (props.to !== undefined) {
			navigate(props.to);
		} else if (props.onClick !== undefined) {
			props.onClick();
		}
	}
	const Icon = props.icon;
	return (
		<Tooltip content={props.tooltip}>
			<button
				className={
					'group mb-2 rounded-full border-2 border-ca-silver p-4 transition-colors hover:border-ca-purple focus:border-ca-purple'
				}
				type="button"
				onClick={handleOnClick}
			>
				<Icon
					className={
						'w-4 text-ca-gray transition-colors group-hover:text-ca-purple'
					}
				/>
			</button>
		</Tooltip>
	);
}

export function SidebarCircleNav(props: {
	icon: ElementType;
	tooltip: string;
	to: string;
}) {
	const Icon = props.icon;
	return (
		<Tooltip content={props.tooltip}>
			<NavLink
				to={props.to}
				className={({ isActive }) =>
					cn(
						'mb-2 rounded-full border-2 p-4 transition-colors hover:border-ca-purple hover:text-ca-purple focus:border-ca-purple',
						isActive
							? 'border-ca-purple text-ca-purple'
							: 'border-ca-silver text-ca-gray',
					)
				}
			>
				<Icon className={'w-4'} />
			</NavLink>
		</Tooltip>
	);
}

function LogoNav() {
	const navigate = useNavigate();

	return (
		<div
			role="button"
			tabIndex={0}
			className="relative cursor-pointer"
			onClick={() => navigate(DEFAULT_PATHS['private'])}
			onKeyDown={() => navigate(DEFAULT_PATHS['private'])}
		>
			<Logo className="h-full w-12" />
		</div>
	);
}

export default function StratosSidebar() {
	const { user } = useAuth0();
	const { activeChannel } = useChannelStore();

	const isSuperAdmin =
		user?.[window._ENV_.REACT_APP_ROLES_CLAIM]?.includes('superadmin');

	const isDevEnv = ['dev', 'development', 'local'].includes(
		window._ENV_.REACT_APP_ENVIRONMENT,
	);

	const openDocPage = () => {
		window.open('http://knowledge.crunchplatform.eu');
	};

	return (
		<div className="sticky top-0 z-10 flex h-screen w-40 shrink-0 flex-col justify-between border-r border-ca-black border-opacity-5 bg-white">
			<div>
				<div className="flex justify-center py-10">
					<LogoNav />
				</div>
				<ul className="group/ul flex flex-col py-10">
					{isFeatureEnabled('actuals', activeChannel) && (
						<SidebarNavLink
							icon={ActualsIcon}
							label="Actuals"
							to="/actuals"
						/>
					)}
					{isFeatureEnabled('strategy', activeChannel) && (
						<SidebarNavLink
							icon={StrategyIcon}
							label="Strategy"
							to="/strategy"
						/>
					)}
					{isFeatureEnabled('recommendations', activeChannel) && (
						<SidebarNavLink
							icon={BarChartIcon}
							label="Recommendation"
							to="/recommendations"
						/>
					)}

					{isFeatureEnabled('global', activeChannel) && (
						<SidebarNavLink
							icon={ActualsIcon}
							label="Global"
							to="/global"
						/>
					)}
				</ul>
			</div>
			<div className="flex flex-col items-center justify-center p-10">
				{isSuperAdmin && isDevEnv && (
					<SidebarCircleNav
						tooltip="Prediction evaluation"
						icon={ChartScatterBoldIcon}
						to="prediction-evaluation"
					/>
				)}
				{isSuperAdmin && (
					<SidebarCircleNav
						tooltip="User management"
						icon={UserManagementBoldIcon}
						to="user-management"
					/>
				)}
				{isFeatureEnabled('uploader', activeChannel) && (
					<SidebarCircleNav
						tooltip="Upload data"
						icon={UploadBoldIcon}
						to="uploads"
					/>
				)}
				<SidebarCircleLink
					tooltip="Link to Knowledge Base"
					icon={HelpIcon}
					onClick={openDocPage}
				/>
				<StratosLogOutButton />
			</div>
		</div>
	);
}
